<template>
  <div style="height:100%">
    <en-table-layout :table-data="tableData.data" @selection-change="selectionChange" @sort-change="handleSortChange"
      :row-key="row => row.id" ref="tableData">
      <div slot="header" style="padding: 4px 12px 4px 12px;" class="row no-gutters align-items-center"
        :style="{ marginBottom: promptText ? '15px' : '' }">
        <div class="card-input">
          <el-input style="width: 150px" v-model="cardIntervalList.card_code_start" placeholder="请输入开始卡号" size="small"
            clearable />—
          <el-input style="width: 150px" v-model="cardIntervalList.card_code_end" placeholder="请输入结束卡号" size="small"
            clearable />
          <div class="card-code-prompt-text" v-text="promptText"></div>
        </div>

        <el-select v-model="params.status" placeholder="请选择" clearable style="width:150px;margin-right: 20px;"
          size="small">
          <el-option label="全部状态" value></el-option>
          <el-option label="未激活" :value="0"></el-option>
          <el-option label="已激活" :value="1"></el-option>
          <el-option v-if="card_type == 5" label="已兑换" :value="3"></el-option>
          <el-option v-if="card_type == 6" label="部分兑换" :value="5"></el-option>
          <el-option v-if="card_type == 6" label="全部兑换" :value="6"></el-option>
          <el-option v-if="card_type == 7" label="配送中" :value="7"></el-option>
          <el-option v-if="card_type == 7" label="配送结束" :value="8"></el-option>
          <el-option label="已禁用" :value="2"></el-option>
          <el-option label="已过期" :value="4"></el-option>
        </el-select>
        <el-input style="width: 300px;" size="medium" placeholder="请输入..." v-model.trim="params.keywords" clearable>
          <el-select v-model="key_word" slot="prepend" placeholder="请选择" style="width: 120px;">
            <el-option label="卡号" value="card_code"></el-option>
            <el-option label="卡密" value="card_key"></el-option>
            <el-option label="手机号" value="binding_member_mobile"></el-option>
          </el-select>
        </el-input>
        <el-button class="ml-3" @click="search" type="primary" size="small">搜索</el-button>
      </div>

      <template slot="toolbar">
        <el-checkbox v-model="is_all" :true-label="1" :false-label="0" style="margin-right:10px;">全选</el-checkbox>
        <six-button :ctx-info="{ params, is_all, idsList, currentTab, checkdLogid, 'rowData': tableData.data }" />
      </template>

      <template slot="table-columns">
        <el-table-column :key="'selection'" :reserve-selection="true" fixed type="selection" width="50" />
        <el-table-column v-if="card_type == 5" :key="'code'" label="编号" min-width="100" fixed
          :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ code(scope.$index, params) }}</template>
        </el-table-column>

        <el-table-column :key="'card_code'" prop="card_code" label="卡号" width="140" fixed
          :show-overflow-tooltip="true"></el-table-column>

        <el-table-column :key="'card_key'" prop="card_key" label="卡密" width="180" fixed
          :show-overflow-tooltip="true"></el-table-column>
        <el-table-column v-if="card_type == 7" :key="'exchange_time'" prop="exchange_time" label="确认兑换时间" width="180">
          <template slot-scope="scope">
            <template v-if="scope.row.exchange_time">{{ scope.row.exchange_time | unixToDate("yyyy-MM-dd hh:mm")
              }}</template>
            <template v-else>————</template>
          </template>
        </el-table-column>
        <el-table-column :key="'binding_member_mobile'" prop="binding_member_mobile" label="用户手机号"
          width="180"></el-table-column>
        <el-table-column v-if="card_type == 6" :key="'available_times'" prop="available_times" label="可兑换次数"
          width="180"></el-table-column>
        <el-table-column v-if="card_type == 6" sortable="custom" :key="'exchange_num'" prop="exchangenum" label="已兑换次数"
          width="180">
          <template slot-scope="scope">{{ scope.row.exchange_num }}</template>
        </el-table-column>
        <el-table-column v-if="card_type == 7" :key="'available_times2'" prop="available_times" label="可配送次数"
          width="180"></el-table-column>
        <el-table-column v-if="card_type == 7" sortable="custom" :key="'exchange_num2'" prop="exchangenum" label="已配送次数"
          width="180">
          <template slot-scope="scope">{{ scope.row.exchange_num }}</template>
        </el-table-column>
        <el-table-column v-if="card_type == 5" :key="'exchange_items'" prop="exchange_items" label="套餐礼包" width="150"
          :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.exchange_items ? scope.row.exchange_items : '————' }}</template>
        </el-table-column>

        <el-table-column v-if="card_type == 5" prop="exchange_time" label="兑换时间" width="130"
          :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <template v-if="scope.row.exchange_time">{{ scope.row.exchange_time | unixToDate("yyyy-MM-dd hh:mm")
              }}</template>
            <template v-else>————</template>
          </template>
        </el-table-column>

        <el-table-column prop="company" label="所属销售公司" width="120" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.company ? scope.row.company : '————' }}</template>
        </el-table-column>

        <el-table-column prop="active_salesman" label="业务员" width="100" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.active_salesman ? scope.row.active_salesman : '————' }}</template>
        </el-table-column>

        <el-table-column :key="'state'" prop="state" label="状态" width="100" :show-overflow-tooltip="true">
          <template slot-scope="{row}">
            {{ row | filtersStatus }} <p v-if="row.delivery_status == 2" style="color: red;">(已暂停配送)</p></template>
        </el-table-column>
        <el-table-column prop="binding_time" label="卡密有效期" width="250" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <template v-if="scope.row.start_time">{{ scope.row.start_time | unixToDate("yyyy-MM-dd hh:mm") }}至{{
      scope.row.end_time | unixToDate("yyyy-MM-dd hh:mm") }}</template>
            <template v-else>————</template>
          </template>
        </el-table-column>
        <el-table-column prop="card_key_remark" label="备注" width="200" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.card_key_remark }}</template>
        </el-table-column>

        <el-table-column label="操作" width="210" fixed="right">
          <template slot-scope="scope">
            <el-button v-if="scope.row.state === 0" size="mini" type="primary"
              @click="cardAlive(scope.row)">激活</el-button>
            <el-button v-if="scope.row.state === 2" size="mini" type="primary"
              @click="cardEnable(scope.row)">启用</el-button>
            <el-button v-if="[1, 5, 7].includes(scope.row.state)" size="mini" type="danger"
              @click="cardDead(scope.row)">禁用</el-button>
            <!-- v-if="scope.row.state === 4" -->
            <el-button v-if="scope.row.state !== 0 && scope.row.state !== 3" size="mini" type="danger"
              @click="cardWantToLiveMoreDays(scope.row)">延期</el-button>
            <el-button size="mini" type="primary" @click="remarkOpen(scope.row)">备注</el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="tableData.page_size" :total="tableData.data_total"></el-pagination>
    </en-table-layout>
    <x-dialog :proxy="remarkDialog">
      <div style="padding:10px">
        <el-input type="textarea" :autosize="{ minRows: 8, maxRows: 10 }" placeholder="请输入100字以内的备注信息" maxlength="100"
          v-model="remarkForm.card_key_remark"></el-input>
      </div>
    </x-dialog>
  </div>
</template>

<script>
import * as API_Card from "@/api/combo-card-shop/card";
import { getcardKeysList } from "@/api/hotGoods";
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import SixButton from "./components/components/six-button.vue";
import mixin from "./components/mixin";
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";

export default {
  name: "cardGrantList",
  components: {
    EnTableLayout,
    SixButton,
    XDialog
  },
  mixins: [mixin],
  props: {
    currentTab: [Number, String]
  },
  filters: {
    filtersStatus (row) {
      if (row.close_status == 2 || row.close_status == 3) {
        return '已禁用'
      } else if (row.state === 1) {
        return '已激活'
      } else if (row.state === 2) {
        return '已禁用'
      } else if (row.state === 3) {
        return '已兑换'
      } else if (row.state === 4) {
        return '已过期'
      } else if (row.state === 5) {
        return '部分兑换'
      } else if (row.state === 6) {
        return '全部兑换'
      } else if (row.state === 7) {
        return '配送中'
      } else if (row.state === 8) {
        return '配送结束'
      } else {
        return '未激活'
      }
    }
  },
  data () {
    return {
      key_word: 'card_code',
      remarkForm: {},
      checkdLogid: '',//在线发放选中的第一个log_id
      remarkDialog: $xDialog.create({
        title: "备注信息",
        width: "40vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.remarkCardKeyTh
      }),
      params: {
        card_id: this.$route.params.card_id,
        log_id: this.$route.params.log_id,
        status: "", // 状态；0：未激活，1：已激活，2已禁用，3已兑换，4已过期
        keywords: "",
        // 排序
        // sort: "",
        page_no: 1,
        page_size: 20
      },
      promptText: "",
      cardIntervalList: {
        card_code_start: "",
        card_code_end: "",
        // prefix: "",
        // fix: ""
      },
      is_all: 0,
      idsList: [],
      tableData: {},
      card_type: "",
    };
  },
  watch: {
    "cardIntervalList.card_code_start": {
      handler (newValue) {
        if (newValue) {
          this.params.status = "";
          this.params.keywords = "";
          this.cardIntervalList.card_code_start = this.regHandle(newValue);
        }
        if (newValue || this.cardIntervalList.card_code_end) {
          this.checkHandle(newValue, this.cardIntervalList.card_code_end);
        } else this.promptText = "";
      }
    },
    "cardIntervalList.card_code_end": {
      handler (newValue) {
        if (newValue) {
          this.params.status = "";
          this.params.keywords = "";
          this.cardIntervalList.card_code_end = this.regHandle(newValue);
        }
        if (newValue || this.cardIntervalList.card_code_start) {
          this.checkHandle(this.cardIntervalList.card_code_start, newValue);
        } else this.promptText = "";
      }
    },
    "params.keywords": {
      handler (newValue) {
        if (newValue) {
          this.cardIntervalList.card_code_start = "";
          this.cardIntervalList.card_code_end = "";
          // this.cardIntervalList.prefix = "";
          // this.cardIntervalList.fix = "";
        }
      }
    },
    "params.status": {
      handler (newValue) {
        if (newValue !== "") {
          this.cardIntervalList.card_code_start = "";
          this.cardIntervalList.card_code_end = "";
          // this.cardIntervalList.prefix = "";
          // this.cardIntervalList.fix = "";
        }
      }
    }
  },
  mounted () {
    this.card_type = this.$route.params.card_type;
    this.Get_TableData();
    _eventEmitter.on("card-grant-list-active", this.Get_TableData_Wrap);
  },
  beforeDestroy () {
    _eventEmitter.off("card-grant-list-active");
  },
  methods: {
    remarkOpen (row) {
      this.remarkDialog.display();
      this.remarkForm = { id: row.id, card_key_remark: row.card_key_remark };
    },
    remarkCardKeyTh () {
      API_Card.remarkCardKeyTh(this.remarkForm).then(res => {
        this.$message.success("保存成功");
        this.Get_TableData()
      });
    },
    selectionChange (val) {
      this.checkdLogid = val[0].log_id;
      this.idsList = val.map(i => i.id);
    },
    handleSortChange ({ prop, order }) {
      if (order) {
        this.params.sort = `${prop}_${order.includes("asc") ? "asc" : "desc"}`;
      } else {
        this.params.sort = "";
      }
      this.Get_TableData();
    },
    /**
     * 获取列表数据
     */
    Get_TableData () {
      if (
        this.params.status === "" &&
        (this.cardIntervalList.card_code_start ||
          this.cardIntervalList.card_code_end)
      ) {
        const start = this.cardIntervalList.card_code_start.substring(
          0
        );
        const end = this.cardIntervalList.card_code_end.substring(
          0
        );
        this.params.card_code_start =
          this.addZero(start);

        this.params.card_code_end =
          this.addZero(end);
        // this.params["ranges[0].prefix"] = this.cardIntervalList.prefix;
        // this.params["ranges[0].fix"] = this.cardIntervalList.fix;
      } else if (this.params.card_code_start) {
        delete this.params.card_code_start;
        delete this.params.card_code_end;
        // delete this.params["ranges[0].prefix"];
        // delete this.params["ranges[0].fix"];
      }
      const params = {
        ...this.params
      }
      params[this.key_word] = params.keywords
      delete params.keywords
      if (params.sort == '') {
        delete params.sort
      }
      if (this.$route.query.active == '1') {//在线发放
        params.provide_log_id = this.$route.params.log_id
        delete params.log_id
      } else {//卡密管理

      }
      getcardKeysList(params).then(res => {
        this.tableData = res;
      });
    },
    regHandle (data) {
      const reg = new RegExp(/^[0-9a-zA-Z]/);
      let reData = "";
      for (let i = 0; i < data.length; i++) {
        if (reg.test(data[i])) reData += data[i];
      }
      return reData;
    },
    /**
     * 校验区间卡号
     * @param start 开始区间
     * @param end 结束区间
     * @param type 检验类型
     */
    checkHandle (start, end, type = "default") {
      let startNaN = 0;
      let endNaN = 0;
      let textContent = "";
      if (start && end) {
        for (let i = 0; i < (start.length >= 5 ? 5 : start.length); i++) {
          if (!(parseInt(start[i]) >= 0)) startNaN++;
          else break;
        }
        for (let i = 0; i < (end.length >= 5 ? 5 : end.length); i++) {
          if (!(parseInt(end[i]) >= 0)) endNaN++;
          else break;
        }
        this.cardIntervalList.prefix = start.substring(0, startNaN);
        this.cardIntervalList.fix = end.substring(0, endNaN);
        if (startNaN != endNaN) textContent = "起始卡号与结束卡号前缀不一致";
        else if (start.substring(0, startNaN) !== end.substring(0, endNaN))
          textContent = "起始卡号与结束卡号前缀不一致";
        else if (
          parseInt(start.substring(startNaN)) != start.substring(startNaN)
        )
          textContent = "起始卡号格式错误";
        else if (parseInt(end.substring(endNaN)) != end.substring(endNaN))
          textContent = "结束卡号格式错误";
        else if (
          parseInt(start.substring(startNaN)) >= parseInt(end.substring(endNaN))
        )
          textContent = "起始卡号数字编号需小于结束卡号";
      } else if (!start) textContent = "起始卡号不能为空";
      else if (!end) textContent = "结束卡号不能为空";
      type === "default"
        ? (this.promptText = textContent)
        : (this.intervalFromData.cardList[type].dialogPromptText = textContent);
    },
    addZero (data) {
      let reData = data;
      if (data.length > 0 && data.length < 6) {
        for (let i = data.length; i < 6; i++) {
          reData = "0" + reData;
        }
      }
      return reData;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-input {
  display: inline-block;
  margin-right: 20px;

  .card-code-prompt-text {
    position: absolute;
    font-size: 12px;
    color: red;
    z-index: 99;
  }
}
</style>
